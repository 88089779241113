import React, {useEffect, useState} from "react";
import Paginator from "../Paginator";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Alert
} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import StyledTableCell from "../utils/StyledTableCell";
import {navigate} from "gatsby";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import { styled } from '@mui/material/styles';

const PREFIX = "CompaniesList";
const classes = {
  paper: `${PREFIX}-paper`,
  pointer: `${PREFIX}-pointer`,
  titleBox: `${PREFIX}-titleBox`,
  title: `${PREFIX}-title`,
  descColumn: `${PREFIX}-descColumn`,
  gridItem: `${PREFIX}-gridItem`,
  form: `${PREFIX}-form`,
}
const RootPaginator = styled(Paginator)(({theme}) => ({
  [`& .${classes.paper}`]: {
    height: '100%',
    border: 'none',
  },
  [`& .${classes.pointer}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.titleBox}`]: {
    background: theme.palette.grey[100],
    color: '#000',
    fontWeight: 'bolder',
    padding: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.descColumn}`]: {
    maxWidth: '250px',
    wordWrap: 'break-word',
  },
  [`& .${classes.gridItem}`]: {
    borderTop: '1px solid #e3e3e3',
  },
  [`& .${classes.form}`]: {
    '& > *': {
      margin: theme.spacing(1),
      width: 195,
    },
  },
}));

const CompaniesList = ({companyCards, actions, location}) => {

  const [nip, setNip] = useState('');
  const [regon, setRegon] = useState('');
  const [name, setName] = useState('');
  const [pkd, setPkd] = useState('');

  useEffect(() => {
    const {searchParams: locationSearchParams} = new URL(location.href);

    const nipParam = locationSearchParams.get('nip');
    const pkdParam = locationSearchParams.get('pkd');
    const regonParam = locationSearchParams.get('regon');
    const nameParam = locationSearchParams.get('name');

    if (nipParam) setNip(nipParam);
    if (regonParam) setRegon(regonParam);
    if (pkdParam) setPkd(pkdParam);
    if (nameParam) setName(nameParam);

  }, [location?.href])

  const getParams = () => {
    const params = {};
    if (nip) params.nip = nip;
    if (regon) params.regon = regon;
    if (pkd) params.pkd = pkd;
    if (name) params.name = name;
    return params;
  }

  return (
    <RootPaginator
      resource={companyCards}
      params={getParams()}
      getResource={params =>
        actions.getCompanyCards(params)
      }
      render={(companies, paginationFooter, loading, error) => (
        <Box py={6}>
          <Paper variant='outlined' className={classes.paper}>
            <Box textAlign='center'>
              <Typography variant='h5' paragraph>
                Lista Podmiotów
              </Typography>
            </Box>
          </Paper>
          {loading ? (
            <Box textAlign='center'>
              <CircularProgress/>
            </Box>
          ) : (
            <>
              {error ? (
                <Alert severity='error'>{error}</Alert>
              ) : (
                <>
                  <form className={classes.form} autoComplete="off">
                    <TextField
                      size='small'
                      name='nip'
                      id="nip"
                      label="NIP"
                      variant="outlined"
                      onChange={e => setNip(e.target.value)}
                      value={nip}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setNip('')}
                              onMouseDown={e => e.preventDefault()}
                              edge="end"
                              size="large"
                            >
                              <CloseOutlinedIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      size='small'
                      name='regon'
                      id="regon"
                      label="REGON"
                      variant="outlined"
                      onChange={e => setRegon(e.target.value)}
                      value={regon}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setRegon('')}
                              onMouseDown={e => e.preventDefault()}
                              edge="end"
                              size="large"
                            >
                              <CloseOutlinedIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      size='small'
                      name='pkd'
                      id="pkd"
                      label="Kod PKD"
                      variant="outlined"
                      onChange={e => setPkd(e.target.value)}
                      value={pkd}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setPkd('')}
                              onMouseDown={e => e.preventDefault()}
                              edge="end"
                              size="large"
                            >
                              <CloseOutlinedIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      size='small'
                      name='name'
                      id="company-name" label="Nazwa podmiotu"
                      variant="outlined"
                      onChange={e => setName(e.target.value)}
                      value={name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setName('')}
                              onMouseDown={e => e.preventDefault()}
                              edge="end"
                              size="large"
                            >
                              <CloseOutlinedIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>NIP</StyledTableCell>
                          <StyledTableCell>REGON</StyledTableCell>
                          <StyledTableCell>Kod PKD</StyledTableCell>
                          <StyledTableCell>Nazwa</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companies.map((data) => (
                          <TableRow
                            key={data.code}
                            hover
                            className={classes.pointer}
                            onClick={() => navigate(`/firmy/${data.code}`)}
                          >
                            <TableCell>{data.nip}</TableCell>
                            <TableCell>{data.regon}</TableCell>
                            <TableCell>{data.mainPkd}</TableCell>
                            <TableCell>{data.name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item>
                        <Box py={2}>{paginationFooter()}</Box>
                      </Grid>
                    </Grid>
                  </TableContainer>
                </>

              )}
            </>
          )}
        </Box>
      )}
    />
  )
}
const mapStateToProps = ({companyCards}) => ({companyCards});
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
