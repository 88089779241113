import React from 'react';
import {Container, Box, Typography} from '@mui/material';
import BaseLoader from './BaseLoader';

const BasePageContent = ({
	                         title,
	                         children,
	                         loading = false,
	                         centered = false,
                         }) => (
	<Container>
		<BaseLoader loading={loading}/>
		{!loading && (
			<>
				{title && (
					<Box p={3} marginTop='15px' paddingBottom='10px'>
						<Typography variant="h4" align="center">
							{title}
						</Typography>
					</Box>

				)}
				<Box pb={4} my={1} textAlign={centered ? 'center' : 'left'}>
					{children}
				</Box>
			</>
		)}
	</Container>
);

export default BasePageContent;
