import React, {Component} from 'react';
import Layout from '../../templates/layout';
import CompaniesList from "../../components/companyCards/companyCardsList";
import BasePageContent from '../../components/utils/BasePageContent';

class FirmyPage extends Component {
  render() {
    return (
      <Layout >
        <BasePageContent>
          <CompaniesList location={this.props.location}/>
        </BasePageContent>
      </Layout>
    );
  }
}

export const Head = () => <title>Lista podmiotów | Zapłatomat</title>;

export default FirmyPage;
