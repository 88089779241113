import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootBackdrop = styled(Backdrop)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.25)'
}));

const BaseLoader = ({ classes, loading = false, ...props }) => {
  return (
    <RootBackdrop {...props} open={loading} >
      <CircularProgress color='inherit' />
    </RootBackdrop>
  );
};

export default BaseLoader;
